import {
  RECEIVE_USER,
  RESET_USER,
  REJECT_USER
} from './user-action-types'

// 初始化用户登录state
const initialState = {
    showLogin:false,
    loginState:false,
    openid: null,
    sex: null,
    nickname: null,
    headimgurl:null,
};

function user(state, action) {
  if (typeof state === 'undefined') state = initialState;
  switch (action.type) {
    // 用户登录
    case RECEIVE_USER:
      return action.payload
    // 用户拒绝登录
    case REJECT_USER:
      return state
    case RESET_USER:
      // 用户退出登录
      return initialState
    default:
      return state
  }
}

export {
  user as default,
  initialState as userInitialState,
};