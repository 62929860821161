import {
  OPEN_MINIOLIST,
  CLOSE_MINIOLIST
} from './minio-action-types'

// 初始化用户登录state
const initialState = {
  isShowMinoList:false,
};

function minioYun(state, action) {
  if (typeof state === 'undefined') state = initialState;
  switch (action.type) {
    // 显示MinioList
    case OPEN_MINIOLIST:
      return action.payload
    // 关闭MinioList
    case CLOSE_MINIOLIST:
      return action.payload
    default:
      return state
  }
}

export {
  minioYun as default,
  initialState as minioInitialState,
};