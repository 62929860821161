export function getQueryVariable(variable)
{
       var query = window.location.search.substring(1);
       var vars = query.split("&");
       for (var i=0;i<vars.length;i++) {
               var pair = vars[i].split("=");
               if(pair[0] === variable){return pair[1];}
       }
       return false;
}


export const urlUpdateParams = function (url, name, value) {
  var r = url;
  if (r !== null && r !== 'undefined' && r !== "") {
   value = encodeURIComponent(value);
   var reg = new RegExp("(^|)" + name + "=([^&]*)(|$)");
   var tmp = name + "=" + value;
   if (url.match(reg) !== null) {
    r = url.replace(reg, tmp);
   }
   else {
    if (url.match("[\?]")) {
     r = url + "&" + tmp;
    } else {
     r = url + "?" + tmp;
    }
   }
  }
  window.history.replaceState({
    path:r,
  },'',r)
 }

// 暂时不需要删除code，在出现了code我就做请求了
// 删除url中某个参数,并跳转
export function funcUrlDel(name){
    const local = window.location;
    var baseUrl = local.origin + local.pathname;
     var query = local.search.substr(1);
     if (query.indexOf(name)>-1) {
         var obj = {}
         var arr = query.split("&");
         for (var i = 0; i < arr.length; i++) {
             arr[i] = arr[i].split("=");
             obj[arr[i][0]] = arr[i][1];
         };
         delete obj[name];
         var url = baseUrl + JSON.stringify(obj).replace(/[\"\{\}]/g,"").replace(/\:/g,"=").replace(/\,/g,"&");
         window.history.replaceState({
          path:url,
        },'',url)
     };
 }
