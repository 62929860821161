import React from 'react';
import styles from './minioList.css';
import Draggable from 'react-draggable';
import PropTypes from 'prop-types';
import {connect} from 'react-redux'
import minioClient from '../../api/minio/index'

import {closeMinioAction} from '../../reducers/minioYun/minio-actions.js'
import {loadMinioListAction} from '../../reducers/minioList/minio-list-actions.js'
import transferToMinioBucketName from '../../utils/oidToFileName.js'
import {uint8ArrayToArray} from '../../utils/typeConversionUtils.js'
import log from "../../lib/log";
import {setProjectTitle} from "../../reducers/project-title";
import closeIcon from "../minio-save/icon--close.svg";
import classNames from "classnames";



class MinioList extends React.Component {
    constructor (props) {
        super(props);
        this.closeMinioList = this.closeMinioList.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.loadMinioFile = this.loadMinioFile.bind(this);
        this.delMinioFile = this.delMinioFile.bind(this);
        this.showDelMinioFile = this.showDelMinioFile.bind(this);
        this.state = {
          value:null,
          showDelete:false,
        }
    }
    componentDidMount() {
      window.minioListSelfs = this;
    }

    componentWillUnmount() {
      window.minioListSelfs = {};
    }

    getProjectTitleFromFilename (fileInputFilename) {
      if (!fileInputFilename) return '';
      // only parse title with valid scratch project extensions
      // (.sb, .sb2, and .sb3)
      const matches = fileInputFilename.match(/^(.*)\.sb[23]?$/);
      if (!matches) return '';
      return matches[1].substring(0, 100); // truncate project title to max 100 chars
    }

    closeMinioList(){
        this.props.closeMinio();
      }
    handleChange(event){
      this.setState({
        value:event.target.value,
        showDelete:this.state.showDelete
      })
    }
    loadMinioFile(){
      let fn = transferToMinioBucketName(this.props.fileName);
      if(this.state.value){
        minioClient.getObject(fn, this.state.value).then(dataStream =>{
          let ab = [];
          dataStream.on('data', function(chunk) {
            ab = ab.concat(uint8ArrayToArray(chunk));
          });
          dataStream.on('end', function() {
            let u8a = new Uint8Array(ab);
            console.log(u8a.buffer);
            window.minioListSelfs.props.loadProject(u8a.buffer).then(() => {
              if (window.minioListSelfs.state.value) {
                const uploadedProjectTitle = window.minioListSelfs.getProjectTitleFromFilename(window.minioListSelfs.state.value);
                window.minioListSelfs.props.onReceivedProjectTitle(uploadedProjectTitle);
                window.minioListSelfs.props.closeMinio();
              }
            })
              .catch(error => {
                log.warn(error);
              });
          });
          dataStream.on('error', function(err) {
            console.log(err)
          })
        });
      }else{
        alert("请先选择你要读取的作品")
      }
    }

    showDelMinioFile(){
      this.setState({
        value:this.state.value,
        showDelete:!this.state.showDelete
      })
    }

    delMinioFile(){
      if(this.state.value){
        let objects = this.props.minioList;
        let delIndex = 0;
        for(let i = 0; i<objects.length; i++){
          if(objects[i].name === this.state.value){
            delIndex = i;
            break;
          }
        }
        let fn = transferToMinioBucketName(this.props.fileName);
        minioClient.removeObject(fn, this.state.value, function(err) {
          if (err) {
            alert("删除失败");
            return console.log('Unable to remove object', err)
          }
          objects.splice(delIndex,1);
          window.minioListSelfs.props.loadMinioList(objects)
          window.minioListSelfs.setState({
            value:window.minioListSelfs.state.value,
            showDelete:!window.minioListSelfs.state.showDelete
          })
        })

      }
      else{
        alert("请先选择你要删除的作品")
      }
    }

  render () {
        let fileList = this.props.minioList.map(obj => {
            return (<div key={obj.name}>
              <label className={styles.radioStyle}>
                <input type="radio" name="select" value={obj.name} onChange={this.handleChange}/>
                <span className={styles.spanLeftStyle}>{obj.name}</span>
              </label>
            </div>)
          });
        return (
          <Draggable
            axis="both"
            handle=".handle"
            defaultPosition={{x: 0, y: 0}}
            position={null}
            onStart={this.handleStart}
            onDrag={this.handleDrag}
            onStop={this.handleStop}>
            <div className={styles.background}>
              <div
                className={styles.rightButton}
                onClick={this.closeMinioList}
              >
                <img
                  className={styles.closeIcon}
                  src={closeIcon}
                  alt=""
                />
              </div>
              <div className={classNames('handle',styles.dragHeader)}>
                按住可拖动
              </div>
              <div className={styles.saveHeader}>
                选择一个你要读取的作品
              </div>
              <form>
                <div className={styles.minioListContent}>
                  {fileList}
                </div>
                <div className={styles.saveButton}>
                  <input type="button" value="读取作品" onClick={this.loadMinioFile}/>
                  <input type="button" value="删除作品" onClick={this.showDelMinioFile}/>
                </div>
              </form>
              {this.state.showDelete? (<div className={styles.minioDeleteBackground}>
                <div className={styles.minioDeleteConfirm}>
                  <div className={styles.minioDeleteText}>真的要删除它吗？</div>
                  <div className={styles.minioDeleteBtn}>
                    <input type="button" value="确定" onClick={this.delMinioFile}/><span>      </span>
                    <input type="button" value="取消" onClick={this.showDelMinioFile}/>
                  </div>
                </div>
              </div>):null}
            </div>
          </Draggable>
        );
    }
}

MinioList.propTypes = {
    isFullScreen: PropTypes.bool,
    messageId: PropTypes.string
};
MinioList.defaultProps = {
    isFullScreen: false,
    messageId: 'gui.loader.headline'
};

const mapStateToProps = (state) => {
  return {
    fileName:state.scratchGui.user.openid,
    minioList:state.scratchGui.minioList.lists,
    loadProject: state.scratchGui.vm.loadProject.bind(state.scratchGui.vm),
  }
}

const mapDispatchToProps = dispatch=>{
  return {
    closeMinio: () => dispatch(closeMinioAction()),
    loadMinioList: (objects) => dispatch(loadMinioListAction(objects)),
    onReceivedProjectTitle: title => dispatch(setProjectTitle(title))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MinioList);
