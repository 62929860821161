import {Client} from 'minio'

var minioClient = new Client({
  endPoint: 'files.ihaniel.cn',
  port: 443,
  useSSL: true,
  accessKey: 'SCRTIDXEV7KFRD5R91JH',
  secretKey: '17hd3ahZ7JGyunLli5UMYl/ZPdYH0mF2Ljjld/A0'
})

export default minioClient;