import {
  OPEN_MINIOSAVE,
  CLOSE_MINIOSAVE
} from './minio-save-action-types'

export const openMinioSaveAction = () => {
  const minioStatus = {
    isShowMinoSave:true,
  }
  return {type:OPEN_MINIOSAVE,payload:minioStatus}
}

export const closeMinioSaveAction = () => {
  const minioStatus = {
    isShowMinoSave:false,
  }
  return {type:CLOSE_MINIOSAVE,payload:minioStatus}
}