import {
  RECEIVE_USER,
  RESET_USER,
} from './user-action-types'


// 微信登录action
export const wxLoginAction = data => {
  // 这里只会接收用户的个人信息
  const userInfo = {
    showLogin:false,
    loginState:true,
    openid:data.openid,
    sex:data.sex,
    nickname:data.nickname,
    headimgurl:data.headimgurl,
  }
  return {type:RECEIVE_USER,payload:userInfo}
}

export const showLoginSurface = () => {
  const userInfo = {
    showLogin:true,
    loginState:false,
    openid:null,
    sex:null,
    nickname:null,
    headimgurl:null,
  }
  return {type:RECEIVE_USER,payload:userInfo}
}

export const noShowLoginSurface = () => {
  return {type:RESET_USER}
}
// 微信登出action,将cookie中的refresh清除并刷新
export const wxLogoutAction = () =>  {
  return {type: RESET_USER}
}