import {
  OPEN_MINIOLIST,
  CLOSE_MINIOLIST
} from './minio-action-types'

export const openMinioAction = () => {
  const minioStatus = {
    isShowMinoList:true,
  }
  return {type:OPEN_MINIOLIST,payload:minioStatus}
}

export const closeMinioAction = () => {
  const minioStatus = {
    isShowMinoList:false,
  }
  return {type:CLOSE_MINIOLIST,payload:minioStatus}
}