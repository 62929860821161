import React from 'react';
import classNames from 'classnames';
import styles from './login.css';
import PropTypes from 'prop-types';
import {connect} from 'react-redux'

import {wxLoginAction,noShowLoginSurface} from '../../reducers/wxLoginReducer/user-actions.js'
import {getQueryVariable,funcUrlDel} from '../../utils/urlParams.js'
import {appId,appSecret} from "../../api/wxInfo";
import request from "../../api";
import {getCookie,setCookie} from "../../utils/operateCookies";
import closeIcon from "./icon--close.svg";

class Login extends React.Component {
    constructor (props) {
        super(props);
        this.getWxUserInfoRequest = this.getWxUserInfoRequest.bind(this);
        this.state = {
          /* 0 代表什么都不显示  1 代表显示扫码 2 代表显示请稍等 */
          isScan:0
        }
    }

    componentDidMount(){
      // 首先检测cookies中是否含有refresh_token
      let refresh = getCookie('REFSCR');
      if(refresh){
        // 如果有refresh_token，不显示扫码登录，则使用refresh_token去请求access_token，并请求获取用户的信息
        // console.log("refresh_token的值是: "+refresh)
        request({
          url:'/sns/oauth2/refresh_token?appid='+appId+'&grant_type=refresh_token&refresh_token='+refresh,
          method: 'get'
        }).then(res => {
          let data = res.data
          if(data.hasOwnProperty('errcode')){
            console.log("微信请求出错")
            console.log(data)
          }else{
            this.getWxUserInfoRequest(data)
          }
        }).catch(err => {
          console.log("请求refresh失败"+err)
          console.log(err)
        })
      }else{
        let code = getQueryVariable("code");
        if(code){
          this.setState({
            isScan:2
          })
          request({
            url:'/sns/oauth2/access_token?appid='+appId+'&secret='+appSecret+'&code='+code+'&grant_type=authorization_code',
            method:'get'
          }).then(res => {
            let data = res.data
            if(data.hasOwnProperty('errcode')){
              console.log("微信请求出错"+data)
              console.log(data)
            }else{
              this.getWxUserInfoRequest(data)
            }
          })
        }else{
          this.setState({
            isScan:1
          })
        }
      }
    }

    getWxUserInfoRequest(data){
      setCookie('REFSCR',data.refresh_token,'d30');
      request({
        url:'/sns/userinfo?access_token='+data.access_token+'&openid='+data.openid,
        method:'get'
      }).then(res => {
        if(res.data.hasOwnProperty('errcode')){
          console.log("请求用户信息出错，具体出错编码查询微信开放平台返回码");
          console.log(data)
        }else{
          if( window.location.href.indexOf('code') !== -1){
            window.location.href = window.location.origin;
          }
          this.props.saveUserInfo(res.data)
        }
      }).catch(err => {
        console.log("something wrong about user's Information with " + err)
      })
    }

    render () {
        return (
            <div className={classNames(styles.background)}
              hidden={!this.props.showLogin}>
              <div
                className={styles.rightButton}
                onClick={this.props.noShowLogin}
              >
                <img
                  className={styles.closeIcon}
                  src={closeIcon}
                  alt=""
                />
              </div>
              <div hidden={(this.state.isScan === 2 || this.state.isScan === 0)} id="login_container">
              </div>
              <h2 hidden={(this.state.isScan === 1 || this.state.isScan === 0)}>请稍等...</h2>
            </div>
        );
    }
}

Login.propTypes = {
    isFullScreen: PropTypes.bool,
    messageId: PropTypes.string
};
Login.defaultProps = {
    isFullScreen: false,
    messageId: 'gui.loader.headline'
};

const mapStateToProps = (state) => {
  return {
    showLogin:state.scratchGui.user.showLogin,
  }
}

const mapDispatchToProps = dispatch=>{
  return {
    saveUserInfo: (data) => {dispatch(wxLoginAction(data))},
    noShowLogin: () => {dispatch(noShowLoginSurface())}
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login);
