import {
  OPEN_MINIOSAVE,
  CLOSE_MINIOSAVE,
} from './minio-save-action-types'

// 初始化用户登录state
const initialState = {
  isShowMinoSave:false,
};

function minioSave(state, action) {
  if (typeof state === 'undefined') state = initialState;
  switch (action.type) {
    // 显示MinioSave
    case OPEN_MINIOSAVE:
      return action.payload
    // 关闭MinioSave
    case CLOSE_MINIOSAVE:
      return action.payload
    default:
      return state
  }
}

export {
  minioSave as default,
  initialState as minioSaveInitialState,
};