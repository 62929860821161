// blob对象转File对象
export const blobToFile = (blob,filename,contentType) => {
  let file = new File([blob], filename, {type: contentType, lastModified: Date.now()});
}

export const uint8ArrayToArray = (uint8Array) => {
  let array = [];
  for (let i = 0; i < uint8Array.byteLength; i++) {
    array[i] = uint8Array[i];
  }
  return array;
}

export const ArrayBufferToBuffer = ab => {
  let buf = new Buffer(ab.byteLength);
  let view = new Uint8Array(ab);
  for (let i = 0; i < buf.length; ++i) {
    buf[i] = view[i];
  }
  return buf;
}

