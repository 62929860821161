import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux'
import Draggable from 'react-draggable';
import classNames from 'classnames'

import styles from './minioSave.css';
import closeIcon from './icon--close.svg';

import {closeMinioSaveAction} from '../../reducers/minioSave/minio-save-actions'
import minioClient from '../../api/minio/index.js'
import transferToMinioBucketName from '../../utils/oidToFileName.js'
import {ArrayBufferToBuffer} from '../../utils/typeConversionUtils'


class MinioSave extends React.Component {
    constructor (props) {
        super(props);
        this.closeMinioSave = this.closeMinioSave.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.saveFileToMinio = this.saveFileToMinio.bind(this);
    }

    closeMinioSave(){
      this.props.closeMinio();
    }
    handleClick(){
      let fn = this.props.fileName
      fn = transferToMinioBucketName(fn)

      minioClient.bucketExists(fn).then(res=>{
        if(res){
          this.saveFileToMinio(fn)
        }
        else{
          minioClient.makeBucket(fn,'cn-north-1').then(res=>{
            console.log('成功')
            this.saveFileToMinio(fn)
          }).catch(err => {
            console.log(err)
          })
        }
      })
    }

    saveFileToMinio(address){
      let title = document.getElementById('minioFileTitle');
      const patern =/[`~!@#$%^&*()_+<>?:"{},.\/;'[\]]/im;
      if(patern.test(title.value)){
        alert("含有非法字符！")
      }else{
        let sb3FileName = title.value+'.sb3';
        this.props.saveProjectSb3().then(content => {
          console.log(content)
          let reader = new FileReader();
          reader.readAsArrayBuffer(content);
          reader.onload = function(result) {
            console.log(result);
            var uploadBuf = ArrayBufferToBuffer(result.target.result)
            minioClient.putObject(address,sb3FileName,uploadBuf,result.total).then(res => {
              console.log(res);
              window.selfs.props.closeMinio();
            })
          }
        });
      }
    }

    componentDidMount(){
      window.selfs = this;
    }

    componentWillUnmount() {
      window.selfs = {}
    }

    render () {
        return (
          <Draggable
            axis="both"
            handle=".handle"
            defaultPosition={{x: 0, y: 0}}
            position={null}
            onStart={this.handleStart}
            onDrag={this.handleDrag}
            onStop={this.handleStop}>
            <div className={styles.background}>
              <div
                className={styles.rightButton}
                onClick={this.closeMinioSave}
              >
                <img
                  className={styles.closeIcon}
                  src={closeIcon}
                />
              </div>
              <div className={classNames('handle',styles.dragHeader)}>
                按住可拖动
              </div>
              <div className={styles.saveHeader}>
                请给你的作品取个好听的名字吧
              </div>
              <div className={styles.inputTitle}>
                <input type="text" id="minioFileTitle"/>
              </div>
              <div className={styles.saveButton}>
                <button onClick={this.handleClick}>保存至云端</button>
              </div>
            </div>
          </Draggable>
        );
    }
}

MinioSave.propTypes = {
    messageId: PropTypes.string
};
MinioSave.defaultProps = {
    messageId: 'gui.loader.headline'
};

const mapStateToProps = (state) => {
  return {
    fileName:state.scratchGui.user.openid,
    saveProjectSb3: state.scratchGui.vm.saveProjectSb3.bind(state.scratchGui.vm),
  }
}

const mapDispatchToProps = dispatch=>{
  return {
    closeMinio: () => dispatch(closeMinioSaveAction()),

  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MinioSave);
