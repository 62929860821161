import {
  LOAD_MINIOLIST
} from './minio-list-action-types'

// 初始化用户登录state
const initialState = {
  lists:null,
};

function minioList(state, action) {
  if (typeof state === 'undefined') state = initialState;
  switch (action.type) {
    // 加载MinioList
    case LOAD_MINIOLIST:
      return action.payload;
    default:
      return state
  }
}

export {
  minioList as default,
  initialState as minioListInitialState,
};